import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56bb5b82"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "about-collections" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntityListTopContent = _resolveComponent("EntityListTopContent")!
  const _component_SpinnerContainer = _resolveComponent("SpinnerContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.loading)
      ? (_openBlock(), _createBlock(_component_EntityListTopContent, {
          key: 0,
          entities: _ctx.entities
        }, null, 8, ["entities"]))
      : (_openBlock(), _createBlock(_component_SpinnerContainer, { key: 1 }))
  ]))
}