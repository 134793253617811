
import { useAboutCollectionsResource, useDocumentTitle } from "@kunsten/core";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import SpinnerContainer from "../../../shared/components/SpinnerContainer.vue";
import EntityListTopContent from "../../entity/EntityListTopContent.vue";

export default defineComponent({
    name: "AboutCollections",
    components: { SpinnerContainer, EntityListTopContent },
    setup() {
        const { t } = useI18n();
        const { entities, loading } = useAboutCollectionsResource({
            page: "1",
            pagination: "false",
            "order[sortNumber]": "asc",
        });

        useDocumentTitle(t("general.base-title"), t("navigation.about-collections"));

        return { entities, loading };
    },
});
